<script>
import {PolizaTodoRiesgo} from "./PolizaSchemas.json";
import BVForm from "@/components/BVForm/BVForm.vue";
import renderSchema from '@/components/BVForm/RenderSchema.vue'
import Swal from "sweetalert2";
import { mapGetters } from 'vuex'

export default {
  components: {
    BVForm,
    renderSchema
  },
  props: {
    vehiculo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allData: null,
      isVisibleNuevoRegistro: false,
      titleNuevoRegistro: 'Nuevo registro',
      isVisibleHistorial: false,
      historial: [],
      ultimoRegistroActivo: null,

    }
  },
  async mounted() {

    this.allData = PolizaTodoRiesgo
    console.log("ALL DATA", this.allData)
    //  JSON.parse(JSON.stringify(await this.$store.dispatch('getpolizaExtracontractual')))
    await this.getHistorial()
    this.ajustesDeLaVisata()

  },
  computed: {
    ...mapGetters(['useMoment']),
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    }
  },
  methods: {
    async getHistorial() {
      const response = await this.$store.getters.fetchGet({ path: `VehiculoPolizaTodoRiesgo/ListaPorVehiculo/${this.vehiculo.id}` })
      this.historial = await response.json()
      // reverse
      this.historial = this.historial.reverse()
    },
    ajustesDeLaVisata() {
      
      const last = this.historial.reduce((prev, current) => {
        return (prev.fechaDeCreacion > current.fechaDeCreacion) ? prev : current
      })
      
      if (last.estaCerrado == 0) {
        console.log(last);
        this.ultimoRegistroActivo = last
        this.titleNuevoRegistro = 'Editar registro'
        this.allData.forEach(item => {
          if (item.type == 'date' && last[item.name] != null) {
            last[item.name] = last[item.name].split('T')[0]
          }
          item.value = last[item.name]
        })
        this.isVisibleNuevoRegistro = true
      } else { 
        this.isVisibleNuevoRegistro = !this.historial.length
        this.isVisibleHistorial = true
        console.log("else", this.isVisibleNuevoRegistro, this.isVisibleHistorial )
      }
    },
    cerrarRegistro() {
      // pregunta y advierte si desea cerrar el registro una vez realizado no se podra volver a editar
      Swal.fire({
        title: '¿Está seguro de cerrar el registro?',
        text: "Una vez cerrado no se podrá volver a modificar",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

        confirmButtonText: 'Cerrar registro'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.getters.fetchPut({ path: `VehiculoPolizaTodoRiesgo/cerrarRegistro/${this.ultimoRegistroActivo.id}` })
            .then(response => {
              if (response.ok) {
                return response.json()
              }
              throw new Error('Error al cerrar el registro')
            })
            .then(async () => {
              await this.getHistorial()

              this.ultimoRegistroActivo = null
              this.titleNuevoRegistro = 'Nuevo registro'
              this.allData.forEach(item => {
                item.value = null
              })

              Swal.fire(
                'Registro cerrado',
                'El registro ha sido cerrado',
                'success'
              )
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: 'Error al cerrar el registro',
                text: error.message,
                showConfirmButton: true,
              })
            })
        }
      })
    },

    callbackOnSubmit(self) {
      let data = { ...self.data }
      data.vehiculoId = this.vehiculo.id

      if (this.ultimoRegistroActivo) {
        const { id } = this.ultimoRegistroActivo

        self.loader = true
        this.$store.getters.fetchPut({ path: `VehiculoPolizaTodoRiesgo/${id}`, data })
          .then(response => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Error al actualizar')
          })
          .then(async () => {
            await this.getHistorial()
            this.ajustesDeLaVisata()
            Swal.fire({
              icon: 'success',
              title: 'Registro actualizado',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al actualizar',
              text: error.message,
              showConfirmButton: true,
            })
          })
          .finally(() => {
            self.loader = false
          })

      }
      else {
        self.loader = true
        console.log("Data", data)
        this.$store.getters.fetchPost({ path: `VehiculoPolizaTodoRiesgo`, data })
        .then(response => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Error al crear el registro')
          })
          .then(async () => {
            await this.getHistorial()
            this.ajustesDeLaVisata()
            Swal.fire({
              icon: 'success',
              title: 'Registro creado',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al crear el registro',
              text: error.message,
              showConfirmButton: true,
            })
          })
          .finally(() => {
            self.loader = false
          })
      }

    },


  }
}

</script>

<template>
  <div>
    <div>
      <div @click="isVisibleNuevoRegistro = !isVisibleNuevoRegistro" class="pointer">
        <h3 class="py-2 border-collapse">{{ titleNuevoRegistro }}</h3>
      </div>
      <b-collapse id="nuevoRegistro" v-model="isVisibleNuevoRegistro">
        <BVForm title="Poliza Todo Riesgo" :schema="allData" :callBackSubmit="callbackOnSubmit">
          <template #otherBtns>
            <button v-if="ultimoRegistroActivo" @click.prevent="cerrarRegistro" class="btn btn-outline-warning">
              Cerrar registro
            </button>
          </template>
        </BVForm>
      </b-collapse>
    </div>
    <div>
      <div @click="isVisibleHistorial = !isVisibleHistorial" class="pointer">
        <h3 class="py-2 border-collapse">Historial</h3>
      </div>
      <div>
        <b-collapse id="historial" v-model="isVisibleHistorial">
          <renderSchema class="mb-5" v-for="registro in historial" :key="registro.id" :schema="allData" :data="registro">
            <template #title>
              <h4 class="mt-4 mb-3">Registro del {{ useMoment(registro.fechaDeCreacion )}}</h4>
            </template>

          </renderSchema>
        </b-collapse>
      </div>
    </div>

  </div>
</template>

<style scoped>
.border-collapse {
  border-bottom: .5px solid #dee2e6;
}
</style>